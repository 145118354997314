<template>
  <div class="fixed left-0 top-0 z-50 h-screen w-full bg-gray-50 p-9 pt-4">
    <BaseTitle tag="h3">{{ t("title") }}</BaseTitle>
    <div class="relative my-4 rounded-lg bg-gray-100 p-4">
      <input
        type="text"
        class="block w-full border-spacing-2 rounded-lg border-2 border-gray-900 bg-gray-50 px-4 py-2 text-gray-950 outline-none"
        :placeholder="t('input-placeholder')"
        v-model="searchValue"
      />
      <div class="absolute right-8 top-1/2 -translate-y-1/2">
        <search-icon />
      </div>
    </div>
    <p v-if="getCitiesGroup.length === 0" class="text-gray-600">
      {{ t("not-found-message") }}
    </p>
    <div class="h-auto max-h-[calc(100vh-208px)] overflow-y-auto">
      <div class="columns-2xs gap-9">
        <BaseSectionText
          v-for="(group, key) in getCitiesGroup"
          :key="key"
          :title="group.group"
          :items="group.cities"
          :custom-classes="{
            baseSection: 'break-inside-avoid',
            baseTitle: 'mt-0',
          }"
        />
      </div>
    </div>
    <close-icon
      class="absolute right-4 top-4 cursor-pointer"
      @click="emit('close-section', true)"
    />
  </div>
</template>
<i18n lang="json" src="@/translations/components/modals/cities.json"></i18n>
<script setup lang="ts">
import BaseSectionText from "@/components/sections/components/BaseSectionText.vue";
import { computed, ref, watch } from "vue";
import BaseTitle from "@/components/base/title/BaseTitle.vue";
import SearchIcon from "@/components/base/icon/icons/SearchIcon.vue";
import CloseIcon from "@/components/base/icon/icons/CloseIcon.vue";
import { useAsyncData, useI18n, useStrapi } from "../../.nuxt/imports";
import type { CityT, StrapiLocale } from "@/schemas/schemas";
import { useRoute } from "vue-router";
import { useCitiesStore } from "@/store/cities";

const { locale, t } = useI18n();
const { find } = useStrapi();
const route = useRoute();
const citiesStore = useCitiesStore();

watch(
  () => route.path,
  () => {
    emit("close-section", true);
  },
);

const emit = defineEmits(["close-section"]);

const searchValue = ref("");

if (!citiesStore.cities.length) {
  const { data } = await useAsyncData("cities", async () => {
    try {
      return await find<CityT>("cities", {
        locale: locale.value as StrapiLocale,
        pagination: {
          page: 1,
          pageSize: 1000,
        },
        fields: ["name", "slug"],
      });
    } catch (e) {
      console.log(e);
    }
  });

  citiesStore.setCities(data.value);
}

const getCitiesGroup = computed(() => {
  const input = searchValue.value.toLowerCase().trim();
  const res = [];

  citiesStore.getAlphabeticalCitiesList.forEach((item) => {
    const filteredCities = (
      item.cities as { link: string; content: string }[]
    ).filter((city) => {
      return city.content.toLowerCase().startsWith(input);
    });

    if (filteredCities.length > 0) {
      res.push({
        group: item.group,
        cities: filteredCities,
      });
    }
  });

  return res;
});
</script>
